exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-consult-index-jsx": () => import("./../../../src/pages/consult/index.jsx" /* webpackChunkName: "component---src-pages-consult-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-hair-care-index-jsx": () => import("./../../../src/pages/hair-care/index.jsx" /* webpackChunkName: "component---src-pages-hair-care-index-jsx" */),
  "component---src-pages-online-shop-index-jsx": () => import("./../../../src/pages/online-shop/index.jsx" /* webpackChunkName: "component---src-pages-online-shop-index-jsx" */),
  "component---src-pages-recruit-1-index-jsx": () => import("./../../../src/pages/recruit1/index.jsx" /* webpackChunkName: "component---src-pages-recruit-1-index-jsx" */),
  "component---src-pages-recruit-2-index-jsx": () => import("./../../../src/pages/recruit2/index.jsx" /* webpackChunkName: "component---src-pages-recruit-2-index-jsx" */),
  "component---src-pages-recruit-top-index-jsx": () => import("./../../../src/pages/recruit-top/index.jsx" /* webpackChunkName: "component---src-pages-recruit-top-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

